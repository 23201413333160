import React, { useState, useEffect } from "react";
import axios from "axios";
import delhiImg from "../assets/pngs/delhi_img.png";
import mumbaiImg from "../assets/pngs/mumbai_img.png";
import merathImg from "../assets/pngs/merath_img.png";
import gajiyabadImg from "../assets/pngs/gajiyabad_img.png";
import homePageAnimation from "../assets/animation/mainAnimation.json";
import Lottie from "react-lottie";
import Popup from "./Popup";

const MainContent = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  // Controls Lottie replay
  const [replayAnimation, setReplayAnimation] = useState(false);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  // Store latitude & longitude in state
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  // Fired when user hovers over our trigger element
  // Automatically play animation on first load
  useEffect(() => {
    setReplayAnimation(true);
    setIsAnimationPlaying(true);
  }, []);

  // Called when user hovers over the Lottie container
  const handleHover = () => {
    // Only restart if not currently playing
    if (!isAnimationPlaying) {
      console.log("hover");

      setIsAnimationPlaying(true);

      // Unmount + remount to restart
      setReplayAnimation(false);
      console.log("animation plaay kr rha h ");
      setTimeout(() => setReplayAnimation(true), 0);
    }
  };

  // Detect when Lottie completes
  const lottieEventListeners = [
    {
      eventName: "complete",
      callback: () => {
        setIsAnimationPlaying(false);
      },
    },
  ];
  const texts = ["इंटरनेट", "मनोरंजन", "शिक्षा", "आशा"];
  const [showPopup, setShowPopup] = useState(false); // Show popup if location fails
  // 1) Lottie options with loop = true
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: homePageAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth <= 1030 || false
  );
  // Update state on window resize
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 1030);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true); // Start animation
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsAnimating(false); // Reset animation state
      }, 500); // Match duration with CSS animation
    }, 3000); // Switch text every 3 seconds

    return () => clearInterval(interval);
  }, [texts.length]);

  // ====> Add this useEffect to toggle body scrolling <====
  useEffect(() => {
    if (showPopup) {
      // Disable background scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Enable background scrolling
      document.body.style.overflow = "auto";
    }
    // Cleanup on unmount
    return () => (document.body.style.overflow = "auto");
  }, [showPopup]);
  const handleButtonClick = async () => {
    if ("geolocation" in navigator) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        // Save coords to state so you can pass to Popup
        setLat(latitude);
        setLng(longitude);
        // --- 1) Call new PREDICT API with lat/lng ---
        const bodyData = {
          key: "GINIE_PREDICT",
          payload: {
            lead: {
              id: 0,
              phoneNumber:"",
              lat: latitude,
              lng: longitude,
            },
            allocate: false,
          },
        };

        try {
          console.log("Calling Predict API with lat/lng:", latitude, longitude);
          console.log(bodyData);
          const response = await axios.post(
            "https://ginie.i2e1.in/predict",
            bodyData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          // Check if serviceable
          const { serviceable } = response.data || {};
          console.log("Serviceable:", serviceable);
          console.log("response", response.data);

          if (serviceable) {
            console.log("Serviceable:", serviceable);
            console.log("response", response.data);
            // Serviceable => NO popup => redirect
            window.location.href = `https://w.wiom.in/cstmr/download_wiom/index.html?lat=${latitude}&lng=${longitude}`;
          } else {
            console.log("Serviceable:", serviceable);
            console.log("response", response.data);
            // Not serviceable => open popup
            setShowPopup(true);
          }
        } catch (error) {
          console.error("Error calling Predict API:", error);
          // If the API call itself fails, fallback to opening popup
          setShowPopup(true);
        }
      } catch (error) {
        // Location failed or denied => redirect with lat=null, lng=null (or open popup if desired)
        console.error("Location access denied or failed. Using null coords.", error);
        setShowPopup(true);

        // window.location.href =
        //   "https://play.google.com/store/apps/details?id=com.i2e1.wiom_gold&hl=en_IN";
      }
    } else {
      // Geolocation not supported => fallback
      console.log("Geolocation not supported. Using null coords.");
      setShowPopup(true);

      // window.location.href =
      //   "https://play.google.com/store/apps/details?id=com.i2e1.wiom_gold&hl=en_IN";
    }
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <main style={styles.mainContent}>
      {/* Lottie (looping) */}

      <div style={{ ...styles.heading, top: isSmallScreen ? "25%" : "20%" }}>
        सपनों से ज़्यादा
      </div>
      <div style={styles.animationContainer}>
        {replayAnimation && (
          <Lottie
            options={defaultOptions}
            eventListeners={lottieEventListeners}
          />
        )}
      </div>
      {/* The small "hover box" on top - triggers handleHover */}
      <div style={styles.hoverTrigger} onMouseEnter={handleHover}></div>

      <button
        style={{ ...styles.buttonMain, top: isSmallScreen ? "60%" : "63%" }}
        onClick={handleButtonClick}
      >
        अभी पाएँ
      </button>
      <Popup showPopup={showPopup} closePopup={() => setShowPopup(false)} latitude={lat} longitude={lng}/>

     
    </main>
  );
};

const styles = {
  mainContent: {
    fontFamily: "Noto Sans",
    width: "100vw",
    height: "80vh",
    maxHeight: "1000px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    // padding: "5vw",
    color: "white",
    position: "relative",
    overflow: "hidden",
  },

  heading: {
    width: "100%",
    maxWidth: "400px",

    fontFamily: "Noto Sans",
    fontSize: "clamp(36px, 5vw, 66px)",
    fontWeight: "700",
    position: "absolute",
    top: "20%", // Start at middle of container
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)",
  },
  textContainer: {
    fontFamily: "Noto Sans",

    position: "relative",
    // overflow: "hidden",
  },

  animationContainer: {
    fontFamily: "Noto Sans",

    position: "absolute",
    top: "35%", // Start at middle of container
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%) scale(1.1)",
    width: "90%",
    minWidth: "1000px", // Don’t shrink below 300px on very small screens
    maxWidth: "3000px", // Do not exceed 800px on very large screens
    overflow: "hidden",
    // width: "100%",                   // or "40%", "70%", etc. for bigger/smaller
    // maxWidth: "600px",             // limit how large it can get
  },
  hoverTrigger: {
    // backgroundColor:"black",
    z: 100,
    transform: "translate(-50%, -50%) scale(1.1)",

    position: "absolute",
    top: "35%", // Start at middle of container
    left: "50%", // Center horizontally
    width: "30%",
    height: "20%",
  },
  animatedText: {
    font: "Noto Sans",
    fontSize: "clamp(64px, 5vw, 128px)",
    fontWeight: "700",
    margin: 0,
    transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
  },
  buttonMain: {
    fontFamily: "Noto Sans",

    position: "absolute",
    top: "63%", // Start at middle of container
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)",
    maxWidth: "380px",
    width: "clamp(75%, 10vw, 400px)", // Minimum 200px, scales to 20% of the viewport width, max 400px
    height: "clamp(40px, 10vh, 70px)", // Minimum 50px, scales to 10% of the viewport height, max 80px
    // margin :"0px 48px",
    // gap: "8px",
    borderRadius: "24px",
    backgroundColor: "#D9008D",
    color: "white",
    fontSize: "clamp(20px, 1.5vw, 24px)", // Scales font size
    fontWeight: "700",
    textAlign: "center",
    border: "none",
    cursor: "pointer",
    // marginTop:"100px"
  },
  buttonHover: {
    backgroundColor: "#B0006F", // Darker hover color
    transform: "scale(1.05)", // Slight scale effect on hover
  },
  popupOverlay: {
    fontFamily: "Noto Sans",

    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popupContainer: {
    fontFamily: "Noto Sans",

    backgroundColor: "white",
    color: "white",
    padding: "2em",
    borderRadius: "12px",
    textAlign: "center",
    maxWidth: "500px",
  },
  popupTitle: {
    text: "Noto Sans",
    color: "black",
    fontSize: "2em",
    marginBottom: "1em",
  },
  cityImages: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    flexWrap: "wrap",
  },
  buttonContainer: {
    fontFamily: "Noto Sans",

    marginTop: "1em",
    backgroundColor: "#D9008D",
    color: "white",
    border: "none",
    borderRadius: "1em",
    padding: "1em 5em",
    cursor: "pointer",
  },
  popupButton: {
    fontFamily: "Noto Sans",

    backgroundColor: "#D9008D", // Pink Background
    color: "white", // White Text Color
    border: "none", // Remove Default Border
    borderRadius: "24px", // Rounded Corners
    padding: "1em 3em", // Padding for Proper Size
    fontSize: "24px", // Font Size Matching the Main Page Button
    fontWeight: "700", // Bold Text
    cursor: "pointer", // Cursor as Pointer
    outline: "none", // Remove Outline
    appearance: "none", // Remove Default Browser Button Styles
    transition: "transform 0.3s ease, background-color 0.3s ease", // Hover Transition
  },
  popupButtonHover: {
    fontFamily: "Noto Sans",
    backgroundColor: "#B0006F", // Darker Pink on Hover
    transform: "scale(1.05)", // Scale Up Slightly
  },

  cityList: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // Three equal columns
    gap: "20px",
  },
  cityColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  cityItem: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Noto Sans",
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "36px",
    letterSpacing: "0px",
    color: "#161021", // Matches your text color
  },
  cityText: {
    marginLeft: "5px",
  },
};

// Global CSS for animation
const globalStyles = `
  .slide-up {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

// Append the global styles to the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);

export default MainContent;



// 