import React, { useEffect, useState } from "react";

const Popup = ({ showPopup, closePopup,latitude,longitude }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!showPopup) return null; // Don't render if popup is not shown

  return (
    <div style={styles.popupOverlay} onClick={closePopup}>
      <div
        style={{
          ...styles.popupContainer,
          width: isSmallScreen ? "90%" : "500px",
          padding: isSmallScreen ? "16px" : "24px",
        }}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
      >
        <div style={styles.popupTitle}>
          हमारी सेवाएं सिर्फ नीचे दी गई जगहों पर ही उपलब्ध हैं।
        </div>

        {/* City List */}
        <div
          style={{
            ...styles.cityList,
            gridTemplateColumns: isSmallScreen
              ? "repeat(3, 1fr)" // 1 column for small screens
              : "repeat(3, 1fr)", // 3 columns for large screens
          }}
        >
          {[
            ["मुंबई", "दिल्ली", "मेरठ", "हापुड़", "मुजफ्फरनगर", "गुरुग्राम"],
            ["मोदीनगर", "नवी मुंबई", "वसई विरार", "गाजियाबाद", "फरीदाबाद", "आगरा"],
            ["बिजनौर", "सहारनपुर", "सकोटी", "नोएडा", "बाहादुरगढ़"],
          ].map((column, colIndex) => (
            <div key={colIndex} style={styles.cityColumn}>
              {column.map((city, index) => (
                <div key={index} style={styles.cityItem}>
                  • <span style={styles.cityText}>{city}</span>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* "ठीक है" Button */}
        <div style={styles.buttonContainer}>
          <button
            style={styles.popupButton}
            onClick={() => {
              window.location.href =
                `https://w.wiom.in/cstmr/download_wiom/index.html?lat=${latitude}&lng=${longitude}`;
            }}
          >
            ठीक है
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles for the popup
const styles = {
  popupOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popupContainer: {
    backgroundColor: "white",
    borderRadius: "12px",
    textAlign: "center",
    maxWidth: "500px",
    width: "90%",
    padding: "24px",
  },
  popupTitle: {
    fontFamily: "Noto Sans",
    color: "black",
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "16px",
  },
  cityList: {
    display: "grid",
    gap: "10px",
    textAlign: "center",
  },
  cityColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  cityItem: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Noto Sans",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#161021",
  },
  cityText: {
    marginLeft: "5px",
  },
  buttonContainer: {
    marginTop: "16px",
  },
    popupButton: {
    fontFamily: "Noto Sans",

    backgroundColor: "#D9008D", // Pink Background
    color: "white", // White Text Color
    border: "none", // Remove Default Border
    borderRadius: "24px", // Rounded Corners
    padding: "24px 3em", // Padding for Proper Size
    fontSize: "24px", // Font Size Matching the Main Page Button
    fontWeight: "700", // Bold Text
    cursor: "pointer", // Cursor as Pointer
    outline: "none", // Remove Outline
    appearance: "none", // Remove Default Browser Button Styles
    transition: "transform 0.3s ease, background-color 0.3s ease", // Hover Transition
    width: "100%", // Full Width
  },
  popupButtonHover: {
    fontFamily: "Noto Sans",
    backgroundColor: "#B0006F", // Darker Pink on Hover
    transform: "scale(1.05)", // Scale Up Slightly
  },
};

export default Popup;
