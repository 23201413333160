import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import successAnimation from "../assets/animation/loader.json"; // Replace with your Lottie animation file
import partnerPlus from "../assets/svgs/partnerPlus.svg";
import partnerPlusStairs from "../assets/svgs/partnerPlusStairs.svg";
import NavbarPartnerPlus from "./NavbarPartnePlus";

const PartnerPlus = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [language, setLanguage] = useState("hindi");

  const handleCall = () => {
    window.open("tel:8880322222");
  };

  const handleLanguageChange = () => {
    setLanguage((prevLanguage) =>
      prevLanguage === "hindi" ? "english" : "hindi"
    );
    console.log(language);
  };

  const textContent = {
    hindi: {
      firstLine: "व्योम के साथ पूरे करें अपने सारे सपने",
      
      secondLine: "बिजनेस को बढ़ाएं और 2x तक पैसा कमाएं",
      downloadButton: "व्योम पार्टनर प्लस ऐप इनस्टॉल करें",
    },
    english: {
      firstLine: "Fulfill all your dreams with Wiom",
      secondLine: "Grow business and earn up to 2x",
      downloadButton: "Download Wiom partner plus app",
    },
  };

  // const handleFileDownload = async (event) => {
  //   event.preventDefault();
  //   setIsDownloading(true);

  //   try {
  //     const downloadUrl =
  //       "https://wiom-partner.s3.ap-south-1.amazonaws.com/Wiom-Partner.apk";

  //       // Open the download in a new tab
  //   window.open(downloadUrl, "_blank");

  //     // Fetch the file as a blob so we wait until the download is complete
  //     const response = await fetch(downloadUrl);
  //     if (!response.ok) {
  //       throw new Error("Download failed");
  //     }
  //     const blob = await response.blob();

  //     // Create a temporary URL and trigger the download
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "Wiom-Partner.apk";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);

  //     // Once the download is complete, hide the loader and navigate
  //     setIsDownloading(false);
  //     window.location.href = `/partnerPlusDownload?curLang=${language}`;
  //   } catch (error) {
  //     setIsDownloading(false);
  //     alert("File download failed. Please try again.");
  //     console.error("Download error:", error);
  //   }
  // };

  const handleFileDownload = async (event) => {
    event.preventDefault();
    setIsDownloading(true);
  
    try {
      const downloadUrl =
        "https://wiom-partner.s3.ap-south-1.amazonaws.com/Wiom-Partner.apk";
  
      // Open the download in a new tab
      window.open(downloadUrl, "_blank");
  
      // Wait for 3 seconds (or adjust as needed)
      await new Promise((resolve) => setTimeout(resolve, 3000));
  
      // Hide loader and navigate
      setIsDownloading(false);
      window.location.href = `/partnerPlusDownload?curLang=${language}`;
    } catch (error) {
      setIsDownloading(false);
      alert("Something went wrong. Please try again.");
      console.error("Download error:", error);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#D3D3D3",
        height: "100vh",
        width: "100vw",
        overflow: "hidden", // Ensures no scrolling
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Main White Content Area */}
      <div
        style={{
          backgroundColor: "#FAF9FC",
          width: "100%",
          height: "100vh",
          maxWidth: "800px",
          boxSizing: "border-box",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Ensures spacing between top and bottom content
          alignItems: "center",
          filter: isDownloading ? "blur(5px)" : "none",
          transition: "filter 0.3s ease",
          paddingTop: "40px", // Gap at the top
          paddingBottom: "40px", // Gap at the bottom
        }}
      >
        <NavbarPartnerPlus
          onHelpClick={handleCall}
          onLanguageClick={handleLanguageChange}
          curLang={language}
        />

        {/* Top Content (Icon and Text) */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={partnerPlus}
            alt="Above Text"
            style={{
              width: "120px",
              height: "auto",
              marginBottom: "16px",
            }}
          />

          <div
            style={{
              fontFamily: "Noto Sans",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              textAlign: "center",
            }}
          >
            {textContent[language].firstLine}
          </div>

          <div
            style={{
              fontFamily: "Noto Sans",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              textAlign: "center",
              marginTop: "8px",
            }}
          >
            {textContent[language].secondLine}
          </div>

          <img
            src={partnerPlusStairs}
            alt="Below Text"
            style={{
              marginTop: "16px",
              width: "90%",
              maxWidth: "700px",
              height: "auto",
            }}
          />
        </div>

        {/* Bottom Button */}
        <Link
          to={`/partnerPlusDownload?curLang=${language}`}
          className="icon-button"
          style={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            width: "90%",
          }}
          onClick={handleFileDownload}
        >
          <div
            style={{
              width: "90%",
              maxWidth: "350px",
              padding: "12px 16px",
              fontSize: "16px",
              fontWeight: "700",
              backgroundColor: "#D9008D",
              color: "#FAF9FC",
              borderRadius: "16px",
              textAlign: "center",
              cursor: isDownloading ? "wait" : "pointer",
            }}
          >
            {textContent[language].downloadButton}
          </div>
        </Link>
      </div>

      {/* Lottie Loader Overlay */}
      {isDownloading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              width: "150px",
              height: "150px",
            }}
          >
            <Lottie options={defaultOptions} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerPlus;
